<template>
  <div v-if="edit || (data && data.length>0)">



    <el-table :data="getItems">
    <!-- Check if bank is present -->
    <template v-if="bank">
      <!-- Column for Domestic Bank -->
      <el-table-column v-if="bank?.id" label="Domestic Bank" prop="bank_name">
        <template #default="{ row }">
          <router-link :to="'/owner/bank/' + bank?.id">{{ bank?.name }}</router-link>
        </template>
      </el-table-column>

      <el-table-column v-else label="Domestic Bank" prop="bank_name">
        <template #default="{ row }">{{ getmybank?.name }}</template>
      </el-table-column>

      <!-- Column for Account Number -->
      <el-table-column label="Account Number" prop="acc_number">
        <template #default="{ row }">{{ row?.acc_number }}</template>
      </el-table-column>
    </template>

    <!-- If bank is not present -->
    <template v-else>
      <!-- Column for Document -->
      <el-table-column :label="text" prop="doc">
        <template #default="{ row }">
          <a href="javascript:;" @click="downloadFileRequest(row?.link)">
            {{ row?.doc }}
          </a>
        </template>
      </el-table-column>

      <!-- Column for Confirmation Document -->
      <el-table-column
        v-if="banking_licenses"
        label="Confirmation Doc"
        prop="confirmation_doc"
      >
        <template #default="{ row }">
          <a v-if="row?.confirmation_doc" href="javascript:;" @click="downloadFileRequest(row?.confirmation_link)">
            {{ row?.confirmation_doc }}
          </a>
        </template>
      </el-table-column>

      <!-- Column for Renewal -->
      <el-table-column label="Renewal" prop="renewal">
        <template #default="{ row }">{{ row?.renewal }}</template>
      </el-table-column>

      <!-- Column for State -->
      <el-table-column label="State" prop="state">
  <template #default="scope">
    <span v-if="scope?.row?.state?.name">{{ scope.row.state.name }}</span>
    <span v-else>-</span>
  </template>
</el-table-column>




    </template>
  </el-table>
    <div>
      <div class="line" />
      <a
        v-if="data && data?.length>num"
        @click="filter=!filter"
        class="weight-500 ml-4 mt-2 d-inline-block cursor-pointer"
      >
        Show
        <span v-if="filter">less</span>
        <span v-else>more</span>
      </a>
    </div>

    <a @click="new_doc=true" class="cursor-pointer ml-4" v-if="edit">Upload New</a>

    <doc-upload v-on:cancel="new_doc=false" v-on:update="$emit('update',$event)" v-if="new_doc"></doc-upload>
  </div>
</template>
<script>
import DocUpload from "../../components/bank/DocUpload";

import {fileMixin} from '@/mixins/fileMixin';

export default {
  mixins: [fileMixin],
  components: { "doc-upload": DocUpload },

  data() {
    return {
      filter: false,
      num: 4,
      new_doc: false,
      mybank: {}
    };
  },
  props: ["data", "text", "bank", "edit", "banking_licenses"],
  mounted() {},
  computed: {
    getmybank() {
      let data = window.localStorage.getItem("data");
      let data_parsed = data ? JSON.parse(data) : null;
      this.mybank.name = data_parsed.name;
      this.mybank.id = data_parsed.user_id;
      return this.mybank;
    },
    getItems() {
      if (!this.filter && this.data) {
        return this.data.slice(0, this.num);
      } else {
        return this.data;
      }
    }
  }
};
</script>